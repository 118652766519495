import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>
      <h2>COMPANY DRIVER</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>High School Diploma or equivalent.</li>
          <li>Valid driver's license.</li>
          <li>Clean driving record.</li>
          <li>Experience may be required or preferred.</li>
          <li>Willingness to adhere to assigned routes, schedules, safety procedures, and transportation laws.</li>
          <li>Familiarity with GPS devices (e.g. Waze or Google Maps)</li>
          <li>Polite and professional disposition.</li>
          <li>Ability to walk, drive, and lift and carry heavy items for extended periods.</li>
          <li>Must be focused and observant.</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
